<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Danh sách cam kết</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <v-btn
        rounded
        color="primary"
        class="mb-5"
        x-small
        large
        @click="openDiaLogPromise"
      >
        <v-icon color="white" dark>mdi-plus</v-icon
        ><span style="color: white">Thêm cam kết</span>
      </v-btn>
      <div>
        <v-row>
          <!-- <v-col cols="6">
            <b-form-input class="mt-6" style="float: right; width: 300px" placeholder="Search" v-model="search_input"></b-form-input>
          </v-col> -->
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Content</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(promise, i) in promises">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-html="promise.content"
                        ></p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-success btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editPromise(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-4"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeletePromise(promise.id)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <!-- <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col> -->
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogPromise"
        max-width="1000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_promise == 'add'" class="headline"
                >Add Promise</span
              >
              <span v-if="type_form_promise == 'update'" class="headline"
                >Edit Promise</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogTagType = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <label style="font-size: 16px">Cam kết</label>
                  <Editor
                    label="Name"
                    v-model="content_input"
                    required
                  ></Editor>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_promise == 'add'"
              @click="btnAddOrEditPromise(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_promise == 'update'"
              @click="btnAddOrEditPromise(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogPromise = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "PromiseList",
  components: {
    Loading: () => import("vue-loading-overlay"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
  },
  data() {
    return {
      is_call_api: false,
      promises: [],
      dialogPromise: false,
      content_input: "",
      type_form_promise: "",
      promise_id: null,
    };
  },
  created() {
    this.getAllPromise();
  },
  methods: {
    async getAllPromise() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/promise");
        if (res.status == 200) {
          vm.promises = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogPromise() {
      this.content_input = "";
      this.type_form_promise = "add";
      this.dialogPromise = true;
    },
    editPromise(index) {
      let data = this.promises[index];
      this.content_input = data.content == null ? "" : data.content;
      this.promise_id = data.id;
      this.type_form_promise = "update";
      this.dialogPromise = true;
    },
    async btnAddOrEditPromise(type) {
      let vm = this;
      let data = {
        content: this.content_input,
      };
      if (type == 1) {
        vm.is_call_api = true;
        try {
          let res = await ApiService.post("prep-app/promise", data);
          if (res.status == 200) {
            vm.getAllPromise();
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            vm.dialogTagType = false;
            vm.is_call_api = false;
          }
        } catch (error) {
          vm.is_call_api = false;
          if (error.response.status == 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        }
      } else {
        try {
          let res = await ApiService.put(
            "prep-app/promise/" + vm.promise_id,
            data
          );
          if (res.status == 202) {
            vm.getAllPromise(vm.data_pagination.current_page);
            Swal.fire({
              title: "",
              text: "The application has been successfully submitted!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            vm.dialogTagType = false;
            vm.is_call_api = false;
          }
        } catch (error) {
          vm.is_call_api = false;
          if (error.response.status == 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        }
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    btnDeletePromise(promise_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/promise/" + promise_id)
            .then(function (res) {
              if (res.status == 202) {
                vm.$toasted.success("Delete successfully!!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getAllPromise();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
